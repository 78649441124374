import { render, staticRenderFns } from "./newsletter-page.vue?vue&type=template&id=626a336d&scoped=true&"
import script from "./newsletter-page.vue?vue&type=script&lang=js&"
export * from "./newsletter-page.vue?vue&type=script&lang=js&"
import style0 from "./newsletter-page.vue?vue&type=style&index=0&id=626a336d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "626a336d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KAutocompleteTechnologies from '@web/components/core/k-autocomplete-technologies'
import KButton from '@web/components/core/k-button'
import KGdpr from '@web/components/core/k-gdpr'
import KInput from '@web/components/core/k-input'
import KLayout from '@web/components/core/k-layout'
import KProductTitle from '@web/components/core/k-product-title'
import KSelect from '@web/components/core/k-select'
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {KAutocompleteTechnologies,KButton,KGdpr,KInput,KLayout,KProductTitle,KSelect,VCol,VContainer,VLayout,VRow})
